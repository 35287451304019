import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './component/home/Home';
import GlassesTryOn from './component/GlassesTryOn';
import './App.css';
import StatusDisplay from './component/StatusDisplay';
import StatusList from './component/StatusList';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/glasses-try-on/:id" element={<GlassesTryOn />} />
        <Route path="/statuses/:id" element={<StatusDisplay />} />
        <Route path="/statuses" element={<StatusList />} />
      </Routes>
    </Router>
  );
}

export default App;
