import React, { useEffect, useRef } from 'react';
import 'aframe';
import 'mind-ar/dist/mindar-face-aframe.prod';

const MindARViewer = ({ model, onCapture }) => {
  const sceneRef = useRef(null);

  useEffect(() => {
    const sceneEl = sceneRef.current;
    console.log(model);

    const onLoaded = () => {
      console.log('Scene loaded');
      const arSystem = sceneEl.systems["mindar-face-system"];
      console.log(arSystem);
      arSystem.start();
      console.log('AR System started');
      arSystem.switchCamera();
      console.log('Camera switched');
    };

    sceneEl.addEventListener('loaded', onLoaded);

    return () => {
      console.log('Unmounting MindARViewer');
      sceneEl.removeEventListener('loaded', onLoaded);
      // const arSystem = sceneEl.systems["mindar-face-system"];
      // if (arSystem) {
      //   arSystem.stop();
      // }
    }
  }, [model]); // Add 'model' to the dependency array

  const handleCapture = () => {
    const canvas = document.querySelector('canvas.a-canvas.a-grab-cursor');
    console.log('Capturing screenshot');

    if (canvas) {
      console.log('Canvas element found');
      document.querySelector('video').pause();

      const videos = document.getElementsByTagName('video');
      const video = videos[videos.length - 1];
      const canvas = document.createElement('canvas');
      const aScene = document.querySelector('a-scene');

      const videoWidth = video.offsetWidth;
      const videoHeight = video.offsetHeight;

      const videoTop = parseFloat(window.getComputedStyle(video).top);

      canvas.width = videoWidth;
      canvas.height = videoHeight + videoTop;

      console.log('Video size:', videoWidth, videoHeight);
      console.log('Video top:', videoTop);
      console.log('Canvas size:', canvas.width, canvas.height);

      canvas.getContext('2d').drawImage(video, 0, videoTop, videoWidth, videoHeight);

      const imgData = aScene.components.screenshot.getCanvas('perspective');

      canvas.getContext('2d').drawImage(imgData, 0, videoTop, videoWidth, videoHeight);

      try {
        canvas.toBlob(async (blob) => {
          console.log('Blob created:', blob);
          if (blob) {
            onCapture(blob);
          }
        }, 'image/png');
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        video.play();
      }
    } else {
      console.error('Canvas element not found');
    }
  };

  return (
    <div className="mindar-container">
      <button className='capture-screenshot-button' onClick={handleCapture}>Capture Screenshot</button>
      <a-scene
        ref={sceneRef}
        mindar-face
        color-space="sRGB"
        embedded
        renderer="colorManagement: true, physicallyCorrectLights"
        vr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false"
        style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0 }}
      >
        <a-assets>
          <a-asset-item
            id="headModel"
            src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.5/examples/face-tracking/assets/sparkar/headOccluder.glb"
          ></a-asset-item>
          <a-asset-item id={model.id} src={`/${model.path}`}></a-asset-item>
        </a-assets>

        <a-camera active="false" position="0 0 0"></a-camera>

        <a-entity mindar-face-target="anchorIndex: 168">
          <a-gltf-model
            mindar-face-occluder
            position="0 -0.3 0.15"
            rotation="0 0 0"
            scale="0.065 0.065 0.065"
            src="#headModel"
            visibility="visible"
          ></a-gltf-model>
        </a-entity>

        <a-entity mindar-face-target="anchorIndex: 168">
          <a-gltf-model
            rotation={model.rotation}
            position={model.position}
            scale={model.scale}
            src={`#${model.id}`}
            visible="visible"
          ></a-gltf-model>
        </a-entity>
      </a-scene>
    </div>
  )
};

export default MindARViewer;
