class MindarModel {
    constructor(id, name, path, position, rotation, scale) {
        this.id = id;
        this.name = name;
        this.path = path;
        this.position = position;
        this.rotation = rotation;
        this.scale = scale;
    }
}

export default MindarModel;