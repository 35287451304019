import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ImageDisplay from './ImageDisplay';


const StatusList = () => {
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchStatuses = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/statuses`);
                setStatuses(response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id)));
            } catch (error) {
                console.log(error);
                setError('Error fetching statuses');
            } finally {
                setLoading(false);
            }
        };

        fetchStatuses();
    }, []);

    const handleImageClick = (imagePath) => {
        setSelectedImage(imagePath);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div style={styles.container}>
            <h1>Status List</h1>
            {statuses.map(status => (
                <div key={status.id} style={styles.statusContainer}>
                    <h2>
                        <Link to={`/statuses/${status.id}`}>Status ID: {status.id}</Link>
                    </h2>
                    <div style={styles.imageContainer}>
                        <ImageDisplay label="Input Image" imagePath={status.inputImagePath} onClick={handleImageClick} />
                        <ImageDisplay label="Resized Image" imagePath={status.resizedImagePath} onClick={handleImageClick} />
                        <ImageDisplay label="Mask Image" imagePath={status.maskImagePath} onClick={handleImageClick} />
                        {status.processedImagesPaths.map((path, index) => (
                            <ImageDisplay key={index} label={`Processed Image ${index + 1}`} imagePath={path} onClick={handleImageClick} />
                        ))}
                    </div>
                </div>
            ))}
            {selectedImage && (
                <div style={styles.modalOverlay} onClick={handleCloseModal}>
                    <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <img src={selectedImage} alt="Selected" style={styles.fullSizeImage} />
                        <button style={styles.closeButton} onClick={handleCloseModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5',
    },
    statusContainer: {
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
    },
    imageContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius:'8px',
        position: 'relative',
    },
    fullSizeImage: {
        maxWidth: '90vw',
        maxHeight: '90vh',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '10px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default StatusList;