import axios from 'axios';

class KlainPocApiService {

    static async uploadImageCapture(blob, environment) {
        const formData = new FormData();        
        formData.append('image', blob, 'capture.png')
        formData.append('environment', environment);

        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log('Image uploaded:', response.data);
        return response.data;
    }
}

export default KlainPocApiService;