import MindarModel from '../model/MindarModel';

class MindarModelRepository {
    static mindarModels = {
      'glasses1': new MindarModel(
        'glasses1',
        "Glasses 1",
        'gltf/glasses1/scene.gltf',
        '0 0 0',
        '0 0 0',
        '0.7 0.7 0.7',
      ),
      'glasses2': new MindarModel(
        'glasses2',
        "Glasses 2",
        'gltf/glasses2/scene.gltf',
        '0 0 -0.5',
        '0 0 0',
        '0.53 0.53 0.53',
      ),
    };
  
    static getAllIds() {
      return Object.keys(this.mindarModels);
    }
  
    static getItemById(id) {
      return this.mindarModels[id] || null;
    }

    static getAllModels() {
      return Object.values(this.mindarModels);
    }
  }

  export default MindarModelRepository;