import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import MindARViewer from './MindarViewer';
import MindarModelRepository from '../data/MindarModelRepository';
import KlainPocApiService from '../data/KlainPocApiService';


const GlassesTryOn = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const environment = queryParams.get('environment');
    
    const handleCapture = async (blob) => {
        const response = await KlainPocApiService.uploadImageCapture(blob, environment);
        navigate(`/statuses/${response.processId}`);
    };

    return (
        <div>
            <MindARViewer
                model={MindarModelRepository.getItemById(id)}
                onCapture={handleCapture}
                environment={environment} // Pass environment to MindARViewer if needed
            />            
        </div>
    );
};

export default GlassesTryOn;
