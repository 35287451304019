import React from 'react';

const ImageDisplay = ({ label, imagePath, onClick }) => {
    if (!imagePath) return null;

    return (
        <div style={styles.imageWrapper}>
            <p>{label}</p>
            <img
                src={imagePath}
                alt={label}
                style={styles.image}
                onClick={() => onClick(imagePath)}
            />
        </div>
    );
};

const styles = {
    imageWrapper: {
        textAlign: 'center',
        padding: '10px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '10px',
        cursor: 'pointer',
    },
    image: {
        width: '250px',
        height: '250px',
        objectFit: 'cover',
        borderRadius: '4px',
    },
};

export default ImageDisplay;
