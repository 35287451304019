import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MindarModelRepository from '../../data/MindarModelRepository';
import Environment from '../../model/Environment';
import './Home.css';

export const Home = () => {
  const [selectedEnvironment, setSelectedEnvironment] = useState(Environment.NATURE); // Preselect 'nature'
  const [models, setModels] = useState([]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await MindarModelRepository.getAllModels();
        setModels(models);
      } catch (error) {
        console.error('Error fetching models:', error);
        // Handle error state if needed
      }
    };

    fetchModels();
  }, []);

  const handleEnvironmentChange = (event) => {
    setSelectedEnvironment(event.target.value);
  };

  return (
    <div className="container">
      <h1 className="heading">Environment</h1>      

      {/* Environment Selection */}
      <div className="environment-select">
        <label className="radio-label">
          <input
            type="radio"
            className="radio-input"
            value={Environment.NATURE}
            checked={selectedEnvironment === Environment.NATURE}
            onChange={handleEnvironmentChange}
          />
          Nature
        </label>
        <label className="radio-label">
          <input
            type="radio"
            className="radio-input"
            value={Environment.OFFICE}
            checked={selectedEnvironment === Environment.OFFICE}
            onChange={handleEnvironmentChange}
          />
          Office
        </label>
        <label className="radio-label">
          <input
            type="radio"
            className="radio-input"
            value={Environment.BEACH}
            checked={selectedEnvironment === Environment.BEACH}
            onChange={handleEnvironmentChange}
          />
          Beach
        </label>
        <label className="radio-label">
          <input
            type="radio"
            className="radio-input"
            value={Environment.RESTAURANT}
            checked={selectedEnvironment === Environment.RESTAURANT}
            onChange={handleEnvironmentChange}
          />
          Restaurant
        </label>
      </div>

      {/* Virtual Try-On Section */}
      <h1 className="heading">Virtual Try-On</h1>

      {/* List of Items */}
      <div className="card-container">
        {models.map(item => (
          <div key={item.id} className="card">
            <Link to={`/glasses-try-on/${item.id}?environment=${selectedEnvironment}`} className="link">
              <div className="card-content">
                <h3 className="card-title">{item.name}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>

      {/* Link to All Image Processing Statuses */}
      <div className="status-link">
        <Link to="/statuses">View All Image Processing Statuses</Link>
      </div>
    </div>
  );
};


export default Home;
